<script>
export default {
  name: "Empty"
};
</script>

<template>
  <div class="empty-box">
    <img src="/data-empty.png">
    <span>数据为空</span>
  </div>
</template>

<style scoped lang="scss">
.empty-box {
  width: 100%;
  height: 100%;
  min-height: 324px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //margin-bottom: 60px;
  img {
    width: 300px;
    height: 240px;
    //width: 35%;
    //height: 63%;
  }
  span{
    margin-top: 30px;
    font-size: 18px;
    color: #686868;
    margin-bottom: 30px;
  }
}
</style>
