import { render, staticRenderFns } from "./thematicCollectionDetail.vue?vue&type=template&id=76654e94&scoped=true"
import script from "./thematicCollectionDetail.vue?vue&type=script&lang=js"
export * from "./thematicCollectionDetail.vue?vue&type=script&lang=js"
import style0 from "./thematicCollectionDetail.vue?vue&type=style&index=0&id=76654e94&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../usr/local/node_16.9.1/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76654e94",
  null
  
)

export default component.exports