<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import { getNewsDetail } from "@/api";

export default {
  components: { MyBreadcrumb },
  data() {
    return {
      breadcrumbList: [],
      newsInfo:{}
    };
  },
  mounted() {
    const pageType = decodeURI(this.$route.query.from);
    const fromUrl = decodeURI(this.$route.query.url);
    const pageId = this.$route.query.id
    const type = this.$route.query.type
    this.breadcrumbList = [
      {
        title: pageType,
        pageQuery: { pageType: this.$route.query.from },
        url: fromUrl,
      },
      { title: "详情" },
    ];
    if (type && pageId) {
      getNewsDetail({id:pageId,type}).then(res=>{
        if (res.code === 200) {
          this.newsInfo = res.data
          this.newsInfo.title = this.newsInfo.title.search('/n') ? this.newsInfo.title.split('/n') : this.newsInfo.title
          this.newsInfo.createTime = this.newsInfo.createTime.split(' ')[0]
          // this.newsInfo.content = this.newsInfo.content.replace(/<img/g,"<img style='max-width:100%;height:auto;'")
        }
      })
    }
  },
  methods:{
    toPreOrNextNews(id) {
      this.$router.replace({path:'/Refresh',query:{
          from: encodeURI(this.newsInfo.newsTypeList[0].newsTypeName),
          sliderChildrenIndex: -1,
          id:id,
          type:this.newsInfo.newsTypeList[0].newsTypeId
        }})
    },
    toLookFile() {
      window.open(this.newsInfo.fileUrl)
    }

  }
};
</script>

<template>
  <div>
    <MyBreadcrumb :breadcrumb-list="breadcrumbList"></MyBreadcrumb>
    <div class="detail-content">
      <div class="detail-title-box">
        <div class="title">
          <template v-if="typeof newsInfo.title === 'object'">
            <div v-for="(item,index) of newsInfo.title" :key="index">
              {{item}}
            </div>
          </template>
          <div v-else>
            {{newsInfo.title}}
          </div>
        </div>
        <div class="detail-info">
          <span class="margin-right-30" v-if="newsInfo.authorName">作者：{{newsInfo.authorName}}</span>
          <span class="margin-right-30">来源：{{newsInfo.source}}</span>
          <span class="margin-right-30">时间：{{ newsInfo.createTime}}</span>
          <span class="margin-right-30">阅读：{{newsInfo.numberViews}}次</span>
        </div>
      </div>
      <div class="content">
        <div class="news-content" v-html="newsInfo.content"></div>
      </div>
      <div class="down-file-text" v-if="newsInfo.fileUrl" @click="toLookFile">
        <span
        >附件：{{newsInfo.fileName}}</span
        >
        <div class="down-box" >
          下载
          <img
            class="down-icon"
            src="@/assets/image/common/downFile-icon.png"
          />
        </div>
      </div>
      <div class="gary-line"></div>
      <div class="turn-page-box">
        <div class="previous-posts" v-if="newsInfo.preNews" @click="toPreOrNextNews(newsInfo.preNews.id)">
          上一篇：{{newsInfo.preNews.title.split('/n').join('')}}
        </div>
        <div class="next-chapter" v-if="newsInfo.nextNews" @click="toPreOrNextNews(newsInfo.nextNews.id)">
          下一篇：{{newsInfo.nextNews.title.split('/n').join('')}}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.content {
  ::v-deep .news-content {
    img {
      max-width: 100% !important;
    }
  }

}
</style>
