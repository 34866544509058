<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import {getVideoInfo} from '@/api'
export default {
  components: { MyBreadcrumb },
  data() {
    return {
      breadcrumbList: [],
      videoInfo:{}
    };
  },
  mounted() {
    const pageType = decodeURI(this.$route.query.from);
    const pageId = this.$route.query.id
    this.breadcrumbList = [
      { title: "工会视频", url: "/UnionVideo" },
      { title: "详情" },
    ];

    if (pageId) {
      getVideoInfo(pageId).then(res=>{
        if (res.code === 200) {
          this.videoInfo = res.data
        }
      })
    }
  },
};
</script>

<template>
  <div>
    <MyBreadcrumb :breadcrumb-list="breadcrumbList"></MyBreadcrumb>
    <div class="detail-content">
      <div class="detail-title-box">
        <div class="title">
          {{videoInfo.title}}
        </div>
        <div class="detail-info">
          <span class="margin-right-30">时间：{{videoInfo.createTime}}</span>
          <span class="margin-right-30">浏览量：{{videoInfo.numberViews}}</span>
        </div>
      </div>
      <div class="content" style="display: flex; justify-content: center">
        <video controls :poster="videoInfo.image" style="width: 850px;height: 500px" :src="videoInfo.videoUrl">
<!--          <source-->
<!--            :src="videoInfo.videoUrl"-->
<!--          />-->
        </video>
      </div>
      <div class="gary-line"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.content {
  ::v-deep .news-content {
    img {
      max-width: 100% !important;
    }
  }
 
}
</style>
