<template>
  <div>
    <MyBreadcrumb :breadcrumbList="breadcrumbList"></MyBreadcrumb>
    <div class="page-content">
      <PageSlider
        v-if="Object.keys(sliderActive).length !== 0"
        sliderLogo="openInfo-logo"
        @sliderClick="sliderClick"
        :sliderList="sliderList"
        :propSliderActiveIndex="sliderIndex"
      ></PageSlider>
      <div class="list-box">
        <div class="list-content" v-if="newsList.length > 0">
          <div
            class="list-content-item"
            v-for="(item, index) of newsList"
            :key="index"
          >
            <div class="list-content-item-title" @click="toDetail(item)">
              <div class="dot"></div>
              <span class="item-title"
                >{{item.title.split('/n').join('')}}</span
              >
            </div>
            <div class="list-content-item-time">{{item.createTime.split(' ')[0]}}</div>
          </div>
        </div>
        <Empty v-else></Empty>
        <MyPagination
          v-if="newsList.length >0"
          @current-change="changeCurrent"
          :total="total"
        ></MyPagination>
      </div>
    </div>
  </div>
</template>
<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import PageSlider from "@/components/PageSilder.vue";
import MyPagination from "@/components/MyPagination.vue";
import { getNewsList } from "@/api";
import Empty from "@/components/Empty.vue";

export default {
  components: { Empty, MyPagination, PageSlider, MyBreadcrumb },
  data() {
    return {
      breadcrumbList: [],
      sliderIndex: 0,
      sliderActive: {},
      sliderList: [],
      queryParams:{
        current:1,
        size:8,
        type:''
      },
      newsList:[],
      total:0
    };
  },
  watch: {
    sliderActive: {
      handler(val, oldVal) {
        this.breadcrumbList = [
          { title: this.$route.meta.pageName },
          { title: val.label },
        ];

      },
      immediate: true,
    },
  },
  mounted() {
    this.newsList = []
    this.sliderList = JSON.parse(localStorage.getItem('treeList')).filter(item=>{
      if (item.id>=14 && item.id<=16) return item
    })
    const pageType = decodeURI(this.$route.query.pageType);
    if (pageType !== "undefined") {
      this.sliderActive = this.sliderList.find(
        (item, index) => item.name === pageType
      );
      this.sliderIndex = this.sliderList.findIndex(
        (item, index) => item.name === pageType
      );
    } else {
      this.sliderActive = this.sliderList[0];
    }
    this.queryParams.type = this.sliderActive.id
    this.getNewsListMethod()
  },
  methods: {
    sliderClick(e) {
      this.sliderActive = e;
      this.queryParams.type = e.id
      this.getNewsListMethod()
    },
    changeCurrent(e) {
      this.queryParams.current = e
      this.getNewsListMethod()
    },
    getNewsListMethod() {
      getNewsList(this.queryParams).then(res=>{
        if (res.code === 200) {
          this.newsList = res.data.records
          this.total = res.data.total
        }
      })
    },
    toDetail(item) {
      this.$router.push({
        name: "OpenInfoDetail",
        query: {
          from: encodeURI(this.sliderActive.name),
          id:item.id,
          type:this.sliderActive.id
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.page-content {
  padding: 0 $pagePadding;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-top: 42px;
  min-width: $minPageWidth;
  .list-box {
    flex: 1;
    margin-left: 34px;
    .list-content {
      .list-content-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #f2f2f2;
        margin-top: 30px;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          border-bottom: none;
        }
        .list-content-item-title {
          color: #686868;
          flex: 1;
          display: flex;
          align-items: center;
          .dot {
            margin-right: 9px;
            width: 8px;
            height: 8px;
            background: #916640;
            border-radius: 50%;
          }
          .item-title {
            @include ellipsis(1050px);
            cursor: pointer;
            &:hover {
              color: #bf0302;
            }
          }
        }
        .list-content-item-time {
          color: #686868;
        }
      }
    }
  }
}
</style>
