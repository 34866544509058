import { render, staticRenderFns } from "./MyPagination.vue?vue&type=template&id=6df78516&scoped=true"
import script from "./MyPagination.vue?vue&type=script&lang=js"
export * from "./MyPagination.vue?vue&type=script&lang=js"
import style0 from "./MyPagination.vue?vue&type=style&index=0&id=6df78516&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../usr/local/node_16.9.1/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6df78516",
  null
  
)

export default component.exports