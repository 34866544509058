import { render, staticRenderFns } from "./unionVideo.vue?vue&type=template&id=7d3af73e&scoped=true"
import script from "./unionVideo.vue?vue&type=script&lang=js"
export * from "./unionVideo.vue?vue&type=script&lang=js"
import style0 from "./unionVideo.vue?vue&type=style&index=0&id=7d3af73e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../usr/local/node_16.9.1/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d3af73e",
  null
  
)

export default component.exports