import { render, staticRenderFns } from "./PartyBuilding.vue?vue&type=template&id=c2969786&scoped=true"
import script from "./PartyBuilding.vue?vue&type=script&lang=js"
export * from "./PartyBuilding.vue?vue&type=script&lang=js"
import style0 from "./PartyBuilding.vue?vue&type=style&index=0&id=c2969786&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../usr/local/node_16.9.1/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2969786",
  null
  
)

export default component.exports