<template>
  <div class="pageFooter" v-if="info">
    <div class="footer-item">
      主办单位：{{ info.sponsor }} 网站维护：{{ info.websiteMaintain }}
    </div>
    <div class="footer-item">
      联系电话：{{ info.phone }} 地址：{{ info.address }}
    </div>
    <div class="footer-item">
      投稿邮箱：{{ info.email }}　<span class="icp" @click="toIcpWeb">蜀ICP备20014495号</span>
    </div>
    <img style="width: 52px;height: 63px;margin-top: 20px;display: block" src="@/assets/image/common/footer-icon.png">
  </div>
</template>

<script>
import { basicsInfo } from "@/api";

export default {
  name: "PageFooter",
  data() {
    return {
      info: JSON.parse(localStorage.getItem('basics'))
    }
  },
  mounted() {
    setTimeout(()=>{
      this.info = JSON.parse(localStorage.getItem('basics'))
    },3000)


  },
  methods:{
    toIcpWeb() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index","_blank")
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/variables";

.pageFooter {
  min-width: $minPageWidth;
  background: #e2e2e2;
  height: 322px;
  @include flex-center();
  flex-direction: column;
  color: #333;
  font-size: 12px;

  .footer-item {
    margin-bottom: 30px;
  }
  .icp {
    cursor: pointer;
    &:hover {
      color: #c20000;
    }
  }
}
</style>
