import Vue from "vue";
import Vuex from "vuex";
import { getTreeList } from "@/api";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    serviceCenterList: [
      {
        title: "法律维权",
        egTile: "LEGAL ENFORCEMENT",
        list: [
          {
            label: "业务办理流程",
            bgImg: require("@/assets/image/serviceCenter/legal-1.png"),
            urlName: "BusinessHandling",
          },
          {
            label: "工会法人资格证登记",
            bgImg: require("@/assets/image/serviceCenter/legal-2.png"),
            urlName: "LegalRegister",
          },
          {
            label: "联系方式",
            bgImg: require("@/assets/image/serviceCenter/legal-3.png"),
            urlName: "ContactWay",
          },
        ],
      },
      {
        title: "基层建会",
        egTile: "FOUNDING",
        list: [
          {
            label: "业务流程",
            bgImg: require("@/assets/image/serviceCenter/founding-1.png"),
            urlName: "BuildFlow",
          },
          {
            label: "办事指南",
            bgImg: require("@/assets/image/serviceCenter/founding-2.png"),
            urlName: "workGuide",
          },
          {
            label: "文本模板及各类表格",
            bgImg: require("@/assets/image/serviceCenter/founding-3.png"),
            urlName: "templateAndForm",
          },
        ],
      },
      {
        title: "困难职工帮扶",
        egTile: "DIFFICULT WORKERS",
        list: [
          {
            label: "相关政策",
            bgImg: require("@/assets/image/serviceCenter/difficult.png"),
            urlName: "RelevantPolicy",
          },
          {
            label: "申请流程",
            bgImg: require("@/assets/image/serviceCenter/difficult-2.png"),
            urlName: "RelevantPolicy",
          },
          {
            label: "文本模板各类表格",
            bgImg: require("@/assets/image/serviceCenter/difficult-3.png"),
            urlName: "RelevantPolicy",
          },
        ],
      },
      {
        title: "助企纾困",
        egTile: "HELPING ENTERPRISES",
        list: [
          {
            label: "助企纾困",
            bgImg: require("@/assets/image/serviceCenter/help.png"),
            urlName: "HelpEnterprises",
          },
        ],
      },
    ],
  },
  getters: {},
  mutations: {
  },
  actions: {

  },
  modules: {},
});
