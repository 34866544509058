import { render, staticRenderFns } from "./relevantPolicy.vue?vue&type=template&id=4945f0e6&scoped=true"
import script from "./relevantPolicy.vue?vue&type=script&lang=js"
export * from "./relevantPolicy.vue?vue&type=script&lang=js"
import style0 from "./relevantPolicy.vue?vue&type=style&index=0&id=4945f0e6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../usr/local/node_16.9.1/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4945f0e6",
  null
  
)

export default component.exports