import { render, staticRenderFns } from "./contactWay.vue?vue&type=template&id=555de38d&scoped=true"
import script from "./contactWay.vue?vue&type=script&lang=js"
export * from "./contactWay.vue?vue&type=script&lang=js"
import style0 from "./contactWay.vue?vue&type=style&index=0&id=555de38d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../usr/local/node_16.9.1/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "555de38d",
  null
  
)

export default component.exports