<template>
  <div class="breadcrumb-box">
    <span class="text-1">你当前位置：</span>

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item v-for="(item, index) of breadcrumbList" :key="index">
<!--        <a v-if="index + 1 !== breadcrumbList.length && item.url" :href="item.url">{{-->
<!--          item.title-->
<!--        }}</a>-->
        <span v-if="index + 1 !== breadcrumbList.length" class="link-span" @click="toPath(item)">{{item.title}}</span>

        <span v-if="index + 1 === breadcrumbList.length">{{ item.title }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "MyBreadcrumb",
  props: {
    breadcrumbList: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {

    };
  },
  methods:{
    toPath(item) {
      this.$router.push(item.url)
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.breadcrumb-box {
  min-width: $minPageWidth;
  display: flex;
  align-items: center;
  height: 102px;
  padding-top: 50px;
  padding-bottom: 30px;
  box-sizing: border-box;
  padding-left: 180px;
  border-bottom: 1px solid #f2f2f2;
  .text-1 {
    font-size: 16px;
    color: #999;
    margin-right: 5px;
  }
  .el-breadcrumb {
    font-size: 16px;
  }
}
::v-deep .el-breadcrumb__inner {
  color: #999 !important;
}
::v-deep .el-breadcrumb__item:last-child {
  .el-breadcrumb__inner {
    color: black !important;
  }
}
::v-deep .el-breadcrumb__inner.is-link {
  font-weight: 400;
}
::v-deep .el-breadcrumb__inner .link-span {
  font-weight: 400;
  color: #999;
  cursor: pointer;
  &:hover {
    color: #999;
  }
}
</style>
