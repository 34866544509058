import { render, staticRenderFns } from "./thematicCollectionList.vue?vue&type=template&id=b69e9666&scoped=true"
import script from "./thematicCollectionList.vue?vue&type=script&lang=js"
export * from "./thematicCollectionList.vue?vue&type=script&lang=js"
import style0 from "./thematicCollectionList.vue?vue&type=style&index=0&id=b69e9666&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../usr/local/node_16.9.1/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b69e9666",
  null
  
)

export default component.exports