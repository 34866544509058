<template>
  <div id="app" class="app-page" ref="appBox">
    <PageHeader></PageHeader>
    <router-view class="max-width" />
    <PageFooter/>
    <scrollTop></scrollTop>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import scrollTop from "@/components/scrollTop.vue";
import { basicsInfo, getTreeList } from "@/api";
import ResizeObserver from 'resize-observer-polyfill';
export default {
  components: {
    PageHeader,
    PageFooter,
    scrollTop,
  },
  metaInfo() {
    return {
      title: "1",
      titleTemplate: (title) => {
        return title
          ? `${title} - ${process.env.VUE_APP_TITLE}`
          : process.env.VUE_APP_TITLE;
      },
    };
  },
  data() {
    return {
      screenScale:1,
      browserScale:1,
      appDom:null
    }
  },
  mounted() {
    this.appDom = document.getElementById('app')
    if (document.documentElement.clientWidth > 1200) {
      this.bodyScale();
    }
    window.addEventListener('resize',this.browserZoom,false)
    window.addEventListener("resize", this.bodyScale, false);
    getTreeList().then(res=>{
      if (res.code === 200) {
        localStorage.setItem('treeList',JSON.stringify(res.data))
      }
    })

  },
  methods: {
    browserZoom() {
        const that = this
        var ratio = 0;
        var screen = window.screen;
        var ua = navigator.userAgent.toLowerCase();
        if (window.devicePixelRatio !== undefined) {
          ratio = window.devicePixelRatio;
        } else if (~ua.indexOf("msie")) {
          if (screen.deviceXDPI && screen.logicalXDPI) {
            ratio = screen.deviceXDPI / screen.logicalXDPI;
          }
        } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
          ratio = window.outerWidth / window.innerWidth;
        }
        if (ratio) {
          ratio = Math.round(ratio * 100);
        }
        that.browserScale = ratio / 100
        this.appDom.style.zoom = this.browserScale * this.screenScale
    },
    bodyScale() {
      const appDom = document.getElementById('app')
      const deviceWidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
      this.screenScale = deviceWidth / 1910
      appDom.style.zoom = this.screenScale * this.browserScale
    },
  },
};
</script>
<style lang="scss">
.max-width {
  width: 1900px;
  max-width: 1900px;
  margin: 0 auto;
}
#app {
  padding: 0;
  height: 100%;


}
</style>
