<template>
  <div class="thematicCollection-page">
    <MyBreadcrumb :breadcrumbList="breadcrumbList"></MyBreadcrumb>
    <div class="thematicCollection-content" v-if="list.length !== 0">
      <div
        class="thematicCollection-content-item"
        v-for="(item, index) of list"
        :key="index"
        @click="toList(item)"
      >
        <img
          class="item-img"
          :src="item.image"
        />
        <div class="item-title">
          {{ item.name }}
        </div>
        <div class="item-time">{{item.createTime.split(' ')[0]}}</div>
      </div>
    </div>
    <div style="padding: 0 180px;">
      <Empty v-if="list.length === 0"></Empty>
      <MyPagination style="padding:0 180px;box-sizing: border-box;margin-bottom: 34px" :size="6" :total="total" @current-change="changeCurrent"></MyPagination>
    </div>

  </div>
</template>
<script>
import MyBreadcrumb from "@/components/MyBreadcrumb.vue";
import {getTopicList} from '@/api'
import MyPagination from "@/components/MyPagination.vue";
import Empty from "@/components/Empty.vue";

export default {
  components: { Empty, MyPagination, MyBreadcrumb },
  data() {
    return {
      breadcrumbList: [],
      list: [],
      total:0,
      queryParams:{
        current:1,
        size:6,

      }

    };
  },
  mounted() {
    this.breadcrumbList = [
      { title: this.$route.meta.pageName },
      { title: "列表" },
    ];
    this.getTopicList()

  },
  methods: {
    toList(item) {
      this.$router.push({
        name: "thematicCollectionList",
        query: { from: encodeURI(item.name),id:item.id },
      });
    },
    getTopicList() {
      getTopicList(this.queryParams).then(res=>{
        if (res.code === 200) {
          this.list = res.data.records
          this.total = res.data.total
        }
      })
    },
    changeCurrent(e) {
      this.queryParams.current = e
      this.getTopicList()
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.thematicCollection-content {
  margin-top: 56px;
  margin-bottom: 173px;
  padding: 0 180px;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px;
  min-width: $minPageWidth;
  .thematicCollection-content-item {
    display: flex;
    flex-direction: column;
    .item-img {
      width: 496px;
      height: 165px;
    }
    .item-title {
      @include ellipsis(496px, 2);
      font-size: 23px;
      color: #333;
      margin-top: 22px;
      margin-bottom: 16px;
      height: 60px;
    }
    .item-time {
      color: #999;
      font-size: 16px;
    }
  }
}
</style>
